body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f13141;
  color: #FFFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* html, body, #root, .App {
  height: 100%;
} */
html, body, #root, main, .sc-dlfnuX{
	height: 100%;
}

.sc-dlfnuX{
	text-align: center;
}
.MuiButtonBase-root{
	position: relative;
	top:50%;
}
#custom-container{
	text-align: center;
 
					
	position: relative;
	top:35%;
}

html, body, #root, main, .sc-dlfnuX{
	height: 100%;
}
.sc-dlfnuX{
	text-align: center;
}
.MuiButtonBase-root{
	position: relative;
	top:50%;
}